import W_8BEN_E from '../../../assets/files/en/W-8BEN-E.pdf';
import W_8IMY from '../../../assets/files/en/W-8IMY.pdf';
import glossary from '../../../assets/files/en/Glossary.pdf';

export default {
    seo: {
        title: 'FATCA Compliance | Bank 131',
        description: 'FATCA Compliance'
    },
    title: 'FATCA Compliance',
    content: {
         information: 'Bank 131 LLC hereby informs you that, per Federal Law of Russia No. 173-FZ dd. ' +
             'June 28, 2014 "On the specifics of performing financial transactions with foreign citizens and ' +
             'legal entities; on the changes to the Code of the Russian Federation on Administrative ' +
             'Offenses and repeal of several provisions of Russian legislation" and Foreign Account Tax ' +
             'Compliance Act (FATCA, US Federal Law), Chapter 4 of the US Internal Revenue Code ' +
             '(which obliges non-US financial institutions to identify customers that are US taxpayers, ' +
             'their beneficiaries, and/or persons directly or indirectly controlling thereof, and transfer ' +
             'information regarding the funds on their accounts to the Internal Revenue Service), the Bank ' +
             'registered with the Internal Revenue Service (IRS).',
         clarification: 'Bank 131 LLC was registered as Participating Foreign Financial Institution (PFFI) ' +
             'and received a Global Intermediary Identification Number (GIIN): 4NB0VB.99999.SL643. ' +
             'The main purpose of FATCA is combating tax avoidance in the US jurisdiction by US taxpayers, ' +
             'concerning their incomes generated through non-US financial institutions.',
         theMainGoal: 'Since January 9, 2020, the Bank has been collecting information that allows ' +
             'identifying the affiliation of a person that resorted to the Bank for business purposes, ' +
             'to persons subject to the foreign country`s legislation on taxation of foreign accounts.<br/>' +
             '<br/>' +
             'When concluding an agreement on any service, you need to fill out the Bank\'s questionnaire containing ' +
             'supplementary FATCA information (the Questionnaire). Also, to confirm the customer\'s tax residence, ' +
             'the Bank\'s employee is entitled to ask specification questions and ask to fill out the Self-Certification Form:',
         taxResidentStatusForAnIndividual: {
             title: 'SELF-CERTIFICATION FORM FOR INDIVIDUALS, PRIVATE-PRACTICE PROFESSIONALS, AND SELF-EMPLOYED',
             link: '/legal/en/PERSONAL_SELF-CERTIFICATION_FORM(CRS)(en).doc'
         },
         taxResidentStatusOfALegalEntity: {
             title: 'FATCA SELF-CERTIFICATION FORM FOR LEGAL ENTITIES & FOREIGN UNINCORPORATED ENTITIES',
             link: '/legal/en/SELF-CERTIFICATION_FORM_OF_LEGAL-ENTITY(CRS)(en).doc'
         },
         footnote: [
             {
                 text: 'Please note that the Bank does not consult on the FATCA application. In case of any ' +
                     'questions, please resort to a professional tax advisor. Also, in case of any ' +
                     'questions regarding the essence and application of FATCA, please resort to the website ' +
                     'of the Internal Revenue Service: <a href="https://www.irs.gov/" target="_blank">https://www.irs.gov/</a>'
             }
         ],
         informationForClients: 'Information for Individual Customers',
         additionalDocuments: 'The Bank is entitled to require you to provide the following supplementary documents based on what you specify in the Questionnaire:',
         additionalDocumentsFootnote: [
             {
                 text: 'You are a US citizen/US taxpayer. You need to provide the filled Form W-9 (please find the form on the website of the Internal Revenue Service): ' +
                     '<a href="http://www.irs.gov/pub/irs-pdf/fw9.pdf" target="_blank">http://www.irs.gov/pub/irs-pdf/fw9.pdf</a>).',
                 numbering: '1.'
             },
             {
                 text: 'You were born in the US but you are not a US citizen. You need to provide the following:',
                 numbering: '2.'
             },
         ],
         subAdditionalDocumentsFootnote: [
             {
                 text: 'DS 4083 — Certificate of Loss of Nationality of the United States of the Bureau of Consular Affairs of the United States Department of State',
                 numbering: 'a.'
             },
             {
                 text: 'pleadings on no nationality of the United States (e.g. a reason why you did not obtain nationality after birth).',
                 numbering: 'b.'
             },
         ],
         legalEntities: 'Information for Legal Entities',
         legalEntitiesDescription: 'The Bank is entitled to require you to provide the following supplementary ' +
             'documents based on what you specify in the Questionnaire: Forms W-9 and W-8 and other documents.',
         legalEntitiesLink: [
             {
                 title: 'Form W-8BEN-E',
                 link: W_8BEN_E
             },
             {
                 title: 'Form W-8IMY',
                 link: W_8IMY
             },
             {
                 title: 'Criteria for Recognizing Clients as Foreign Taxpayers. Methods for Obtaining Required Information from Clients',
                 link: '/legal/en/Grounds_for_classification_as_foreign_taxpayers(en).doc'
             },
             {
                 title: 'CONSENT/ WAIVER ON DISCLOSURE OF INFORMATION TO FOREIGN TAX AUTHORITIES AND RUSSIAN AUTHORIZED BODIES',
                 link: '/legal/en/INFORMATION_PERMIT(en).docx'
             },
             {
                 title: 'Glossary',
                 link: glossary
             },
         ]
     }
}